import React from "react"
import { NewsCard } from "../../components/card"
import { PageTitle } from "../../components/copy"
import Layout from "../../components/layout"
import "./index.scss"

const NewsScreen = ({ news }) => {
  return (
    <Layout>
      <div className="newsScreen u-lg-maxWidth">
        <PageTitle
          className="newsScreen-pageTitle"
          title="News"
        />
        {
          news.allNews.edges.reverse().map(item => (
            <NewsCard
              className="newsCard-item"
              key={item.node.id}
              news={item.node}
            />
          ))
        }
      </div>
    </Layout>
  )
}

export default NewsScreen
